import React from 'react';
import OffersProducts from '../../Components/OffersProducts/OffersProducts';

function Offers() {
  return (
    <>
      <OffersProducts/>

    </>
  )
}

export default Offers