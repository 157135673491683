import React from 'react';
import WeddingsHero from '../../Components/Hero/WeddingsHero';

function Weddings() {
  return (
    <>
        <WeddingsHero />
    </>
  )
}

export default Weddings