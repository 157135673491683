import React from 'react';
import QuezonHero from '../../Components/Hero/QuezonHero';
import MapQuezon from '../../Components/Map/MapQuezon';

function Quezon() {
  return (
    <>
        <QuezonHero/>
        <MapQuezon/>
    </>
  )
}

export default Quezon