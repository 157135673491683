import React from 'react';
import BataanHero from '../../Components/Hero/BataanHero';
import MapBataan from '../../Components/Map/MapBataan';

function Bataan() {
  return (
    <>
      <BataanHero/>
      <MapBataan/>
    </>
  )
}

export default Bataan