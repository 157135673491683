import React from 'react'
import ConInfo from '../../Components/Contacts/ConInfo';

function Contact() {
  return (
    <>
        <ConInfo/>
    </>
  )
}

export default Contact