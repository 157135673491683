import React from 'react'
import Jobs from '../../Components/Careers/Jobs'

function Career() {
  return (
    <>
        <Jobs />
    </>
  )
}

export default Career