import React from 'react';
import Vshero from '../../Components/Hero/Vshero';
import MapVs from '../../Components/Map/MapVs';

function Vshotel() {
  return (
    <>
        <Vshero/>
        <MapVs/>
    </>
  )
}

export default Vshotel