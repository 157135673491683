import styled from "styled-components";

export const JobWrap = styled.div`
    padding: 50px;
`;

export const JobMain = styled.div`
    background-color: saddlebrown;
    text-align: center;

    h1{
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        font-size: 50px;
    }
`;

export const JBody = styled.div`


`;

export const JobRow = styled.div`

`;